$(document).ready(function () {
    $('.location-list .content-scroll').each(function() {
        const el = this;
        // check if the content is overflowing past where it is being displayed
        const isOverflowing = el.clientHeight < el.scrollHeight;
        if (isOverflowing) {
            const $btn = $('#read-more-btn-' + $(this).data('location-id'));
            $btn.css({display: 'block'});
        }

    });
    $('.location-list').on('click', '.read-more', function () {
        const $btn = $(this);
        const $content = $('#location-content-' + $btn.data('location-id'));
        const $cardBody = $(this).closest('.card-body');
        const $cardAutoheight = $(this).closest('.card-autoheight');
        if ($btn.data('state') === 'closed') {
            $btn.children('.label').text($btn.data('read-less-text'));
            $btn
                .data('state', 'open')
                .addClass('active')
            ;
            $content.data('orig-height', $content.css('max-height'));
            $cardAutoheight.data('orig-height', $cardAutoheight.css('height'));
            $cardBody.data('orig-height', $cardBody.css('height'));
            $content.css({'max-height': '5000px'});
            $cardAutoheight.css({'height': 'auto'});
            $cardBody.css({'height': 'auto'});
        } else {
            $btn.children('.label').text($btn.data('read-more-text'));
            $btn
                .data('state', 'closed')
                .removeClass('active')
            ;
            $cardAutoheight.css({'height': $cardAutoheight.height() + "px"}); // set the height so it can animate properly - auto -> a number does not work
            $cardBody.css({'height': $cardBody.height() + "px"}); // set the height so it can animate properly - auto -> a number does not work
            $content.css({'max-height': $content.height()});    // if we do not set the max height to the current height the browser is trying to animate from 5000px -> orig-height which looks weird
            setTimeout(function() {
                $content.css({'max-height': $content.data('orig-height')});
                $cardAutoheight.css({'height': $cardAutoheight.data('orig-height')});
                $cardBody.css({'height': $cardBody.data('orig-height')});
            }, 500);
        }
    });
});